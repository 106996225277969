import { components } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource/noto-sans'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: import('@/components/header.js'),
    'embed-video': import('@/components/embed/video.js'),
    video: import('@/components/video.js'),
    posts: import('@/components/posts.js'),
    gallery: import('@/components/gallery.js'),
})
